export default function () {
  return [
    {
      fullname: "Новиков Николай",
      role: "Наблюдатель",
      department: "Архитектурно-строительные решения",
      position: "Главный специалист",
    },
    {
      fullname: "Болатов Ерлан",
      role: "Редактор",
      department: "Архитектурно-строительные решения",
      position: "Главный специалист",
    },
    {
      fullname: "Жансугуров Елбай",
      role: "Редактор",
      department: "Архитектурно-строительные решения",
      position: "Главный специалист",
    },
    {
      fullname: "Костин Никита",
      role: "Редактор",
      department: "Архитектурно-строительные решения",
      position: "Главный специалист",
    },
    {
      fullname: "Арнольд Светлана",
      role: "Редактор",
      department: "Архитектурно-строительные решения",
      position: "Главный специалист",
    },
  ];
}
